import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Document, Page } from 'react-pdf';
import Pagination from '@material-ui/lab/Pagination';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = (theme) => ({
    pdfContainer: {
        display: 'flex',
        alignItems: 'center',        
        flexDirection: 'column'
    },
    pagination: {
        marginBottom: '20px'
    }
});

class Viewer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pdfBase64: `data:application/pdf;base64,${props.pdf}`,
            pageCount: null,
            currentPage: 1,
            pageHeight: 0
        }
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ pageCount: numPages, pageHeight: window.innerHeight - (numPages > 1 ?  110 : 84) });
    }

    handlePageChange = (event, value) => {
        this.setState({ currentPage: value });
    }

    render() {
        const { classes } = this.props;
        const { pageCount, currentPage, pageHeight, pdfBase64 } = this.state;


        return (
            <div className={classes.pdfContainer}>
                {pageCount > 1 && <Pagination count={pageCount} size="small" onChange={this.handlePageChange} className={classes.pagination}  />}
                <Document file={pdfBase64} onLoadSuccess={this.onDocumentLoadSuccess} loading='Dokument se načítá....' >
                    <Page pageNumber={currentPage} height={pageHeight} />
                </Document>
                
            </div>
        )
    }
}

export default withStyles(useStyles)(Viewer)
