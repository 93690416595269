import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = (theme) => ({
    
});

class TitleBar extends React.Component {
    constructor(props) {
        super(props);

        this.getTitle = this.getTitle.bind(this);

        this.state = {
            title: this.getTitle(props.type, props.number)
        }
    }

    getTitle(type, number) {
        if(type.startsWith('I')) {
            if(type === 'I') {
                return `Faktura ${number}`;
            } else if(type === 'IZ' || type === 'IR') {
                return `Záloha ${number}`;
            }
        } else if(type.startsWith('O')) {
            return `Objednávka ${number}`;
        }
       
        return `Dokument ${number}`;
    }

    render() {
        const { title } = this.state;

        return (
            <Typography variant="h6">
                {title}
            </Typography>
        )
    }
}

export default withStyles(useStyles)(TitleBar)
