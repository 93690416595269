import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import DoneIcon from '@material-ui/icons/Done';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
const useStyles = (theme) => ({
    paidAvatar: {
        background: '#2FAC61',
    },
    content: {
        padding: '0 20px'
    },
    payDetailInfo: {
        marginBottom: '10px'
    },
    payQrCode: {
        padding: '0 20%',
    }
});

class PayDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            details: props.details
        }
    }


    render() {
        const { classes } = this.props;
        const { details } = this.state;

        let content;
        if(details.IsPaid) {
            content = 
            <Card>
                <CardHeader
                    avatar={
                    <Avatar className={classes.paidAvatar}>
                        <DoneIcon/>
                    </Avatar>
                    }        
                    title={
                    <Typography variant="h6" color="textSecondary">
                        Uhrazeno
                    </Typography>
                    }
                />
            </Card>
        } else {
            content = 
            <Card>
                <CardHeader
                    title="Platební údaje"
                />
                <CardMedia
                    className={classes.payQrCode}
                    src={`data: image/png;base64,${details.Base64QrPayment}`}
                    component="img"
                    title="QR Platba"
                />
                <CardContent className={classes.content}>
                    <Typography variant="h6" color="textSecondary">
                    Číslo účtu
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.payDetailInfo}>
                    {`${details.Account}/${details.Code}`}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                    Variabilní symbol
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.payDetailInfo}>
                    {details.VariableSymbol}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                    Částka
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.payDetailInfo}>
                    {`${details.Amount},- ${details.Currency}`}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                    Splatnost
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.payDetailInfo}>
                    {new Intl.DateTimeFormat('cs-CZ').format(new Date(details.Maturity))}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing>
                    {details.ShowPayOnline &&
                    <Button variant="contained" fullWidth={true} aria-label="add to favorites" color="secondary">          
                    Zaplatit online
                    </Button>    
                     }                
                </CardActions>
                
                </Card>
        }
        return (
            <div>
                {content}
            </div>
        )
    }
}

export default withStyles(useStyles)(PayDetails)
