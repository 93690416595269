import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import _ from "lodash";
const useStyles = (theme) => ({   
    content: {
        padding: '0 20px'
    },
    button: {
        letterSpacing: '4px'
    }
});

class LinkedDocuments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            documents: _.groupBy(props.documents, 'Type'),
        } 
    }


    render() {
        const { classes } = this.props;
        const { documents } = this.state;

        return (
            <div>                
                {Object.keys(documents).map((group) => 
                    <Card key={group}>
                        <CardHeader subheader={group === 'InvoiceOut' ? 'Spárované faktury' : (group === 'Advance' ? 'Spárované zálohy' : 'Spárované objednávky')}/>
                        <CardContent className={classes.content}>
                            {documents[group].map((doc, index) =>
                                <Button key={index} className={classes.button} fullWidth={true}  variant="outlined" size="medium" color="primary" href={doc.Link}>
                                    {doc.Number}
                                </Button>
                            )} 
                        </CardContent>
                    </Card>                    
                )}                                
            </div>
        )
    }
}

export default withStyles(useStyles)(LinkedDocuments)
