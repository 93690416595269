import React from 'react';
import ReactDOM from 'react-dom';
import App from './Components/App';
import * as serviceWorker from './serviceWorker';
import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/performance";

const firebaseConfig = {
  apiKey: "AIzaSyBotZ7kTSLHApHgr8P8VoCWovAZS6r2fLs",
  authDomain: "ekonom-online.firebaseapp.com",
  databaseURL: "https://ekonom-online.firebaseio.com",
  projectId: "ekonom-online",
  storageBucket: "ekonom-online.appspot.com",
  messagingSenderId: "1025354341460",
  appId: "1:1025354341460:web:243bc259e671261a19a1c1",
  measurementId: "G-H2XE2HR30Q"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.performance();

ReactDOM.render(
    <App firebase={firebase} />,
    document.getElementById('root')
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
