import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import GetAppIcon from '@material-ui/icons/GetApp';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Viewer from './Viewer';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TitleBar from './TitleBar';
import PayDetails from './PayDetails';
import LinkedDocuments from './LinkedDocuments';

const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
    },

    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        background: '#3498DB',
        height: '40px'
    },

    toolbar: {
        height: '40px',
        minHeight: '40px'
    },

    content: {
        position: 'absolute',
        height: '100%'
    }, 

    viewerContainer: {
        background: '#eee',
        height: '100%',
    },

    viewerInnerContainer: {
        marginTop: '20px',
    },

    sidebarContainer: {
        background: '#fff',
        height: '100%',
    },

    sidebarInnerContainer: {
        marginTop: '20px',
    },

    payDetailsAndDocumentLinksPaper: {
        margin: '25px 0 0 0'
    },

    downloadButton: {
        background: '#2FAC61',
        color: '#fff',
        height: '50px'
    },

    payDetailsContainer: {

    },
      
    linkedDocumentsContainer: {
        marginTop: '25px'
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 2,
        color: '#fff',
    },
});

class App extends React.Component {
    constructor(props) {
        super(props);

        this.handleDownload = this.handleDownload.bind(this);

        this.state = {
            isLoading: true,
            error: null,
            data: null,
            token: new URLSearchParams(window.location.search).get("token")
        }
    }

    componentDidMount() {
        let statusCode;
        fetch(process.env.REACT_APP_PUBLIC_DOCUMENT_API_URL + '?token=' + this.state.token)
            .then(res => {
                statusCode = res.status;
                this.setState({isLoading: false});
                return res.json();
            })
            .then(data => {
                if(statusCode !== 200) {
                    let msg = data.Message || data.message;
                    if(msg === 'not-found') {
                        msg = 'Neplatný token';
                    }
                    this.setState({error: msg});
                } else {
                    this.setState({data});
                }
            })
            .catch(error => {
                var msg = error.Message || error.message || error         
                this.setState({error: msg, isLoading: false})
            })            
    }

    handleDownload() {
        const linkSource = `data:application/pdf;base64,${this.state.data.Base64Document}`;
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);

        downloadLink.href = linkSource;
        downloadLink.target = '_self';
        downloadLink.download = 'dokument.pdf';
        downloadLink.click(); 
        document.body.removeChild(downloadLink);
    }

    render() {
        const { classes } = this.props;
        const { isLoading, error, data } = this.state;

        return (
            <div>
                <div className={classes.root}>
                    <CssBaseline />
                    {isLoading &&   
                        <Backdrop className={classes.backdrop} open={true}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    }
                    <AppBar position="fixed" className={classes.appBar}>
                        <Toolbar className={classes.toolbar}>
                            {data && data.PayDetails && <TitleBar type={data.PayDetails.Type} number={data.PayDetails.Number}/>}
                        </Toolbar>
                    </AppBar>
                    <Grid container className={classes.content}>
                        <Hidden only="xs">
                            <Grid item sm={9}>
                                <div className={classes.viewerContainer}>
                                    <Toolbar className={classes.toolbar} />
                                    <Container maxWidth="lg" className={classes.viewerInnerContainer}> 
                                        <div>
                                            {data && data.Base64Document && <Viewer pdf={data.Base64Document}/>}
                                        </div>
                                    </Container>
                                </div>
                            </Grid>
                        </Hidden>
                        <Grid item sm={3} xs={12}>
                            <div className={classes.sidebarContainer}>
                                <Toolbar className={classes.toolbar} />
                                <Container maxWidth="lg" className={classes.sidebarInnerContainer}>    
                                    {data && <Button
                                        variant="contained"
                                        className={classes.downloadButton}
                                        fullWidth={true}
                                        onClick={this.handleDownload}
                                        startIcon={<GetAppIcon />}
                                    >
                                        Stáhnout PDF
                                    </Button>}  
                                    <div className={classes.payDetailsAndDocumentLinksPaper}>
                                        <div className={classes.payDetailsContainer}>
                                            {data && data.PayDetails && <PayDetails details={data.PayDetails} />}
                                        </div>
                                        <div className={classes.linkedDocumentsContainer}>
                                            {data && data.LinkedDocuments && <LinkedDocuments documents={data.LinkedDocuments} />}
                                        </div>
                                    </div>                                    
                                </Container>
                            </div>
                        </Grid>
                    </Grid> 
                    <Dialog
                        open={!!error}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Při načítání dat se vyskytla chyba"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {error}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {this.setState({error: null})}} color="primary">
                                Zavřít
                            </Button>
                        </DialogActions>
                    </Dialog>              
                </div>
            </div>            
        )
    }
}

export default withStyles(useStyles)(App)
